
body,
html,
a {
    font-family:  'Motiva-Sans-Bold', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}



body {
    height: 100vh;
    margin: 0;
    /* background: #ffffff; */

    border: 0.01px solid white;

}

span.underline{
    position: relative;
    white-space: nowrap;
    /* font-weight: 700;
    text-underline-position: under;
    text-decoration: rgb(255, 176, 92) solid underline;
    text-underline-offset: -0.2em;
    text-decoration-thickness:30% */
}
span.underline::before {
    /* Highlight color */
    background-color:   rgb(255, 176, 92) ; 
    white-space: nowrap;
    content: "";
    position: absolute;
    width: calc(100% + 4px);
    height: 0.3em;
    left: -2px;
    bottom: 0;
    z-index: -1;
    transform: rotate(-1.0deg);
  }
span.bold{
    
    font-weight: 900;

}

span, p{
    font-size: 14pt;
}

.app {
    position: fixed;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background:
        radial-gradient(farthest-side at bottom left,
            rgba(157, 98, 155, 0.5),
            transparent 40%),
        radial-gradient(farthest-corner at bottom right,
            rgba(225, 141, 141, 0.3),
            transparent 30%),
        radial-gradient(at 20% 10%,

            rgb(243 136 40 / 24%),
            transparent 20%),
        radial-gradient(farthest-corner at top right,
            rgba(179 54 179 / 33%),
            transparent 30%);

}

.c1arousel .ant-row   {
    padding: 25px;
    height: 100%;
    /* margin-bottom: 20px; */
}

.contentPicture{
    border-radius: 10px;
    max-width: 100%;
    max-height: 100%;
    display: block;  
    width:100%
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}


a:not(.nohover):hover {
    color: #a00065;
}

input,
textarea {
    border-radius: 4px;
    border: 0;
    background: rgb(241, 242, 243);
    transition: all 0.3s ease-in-out;
    outline: none;
    width: 100%;
    padding: 1rem 1.25rem;


}

textarea:focus-within {
    background: none;
    box-shadow: #b336b3 0px 0px 0px 1px;
}

div{
    font-family:  'Motiva-Sans-Light', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    
    font-family:  'Motiva-Sans-Bold', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: #413d3e;
    font-size: 36px;
    line-height: 1.18;


}

@media only screen and (max-width: 890px) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: 27px;
    }
}

@media only screen and (max-width: 414px) {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: 22px;
    }
}

@media only screen and (max-width: 890px) {

    p {
        font-size: 15px !important;
    }
}

p {
    color: #413d3e;
    font-size: 21px;
    line-height: 1.41;
}

h1 {
    font-weight: 600;
}

a {
    text-decoration: none;
    outline: none;
    color: #b336b3;


}
@media only screen and (max-width: 1050px) {
    div.bigLogo{

    
      
    }
}

#intro > div {
    padding: 20px;
}
@media only screen and (min-width: 1050px) {
div.bigLogo{
    transform: scale(3) translate(6%, 20px); 
    transform-origin: top left;
    
    /* position: absolute;
    top:90px;
    left:8%; */
    /* transform: translate(10%, 180px); */
    transition: all 0.5s;
}
}

.carouselCard{
    padding: 10px;
}
@media only screen and (max-width: 890px) {
    .carouselCard{
        padding: 25x;
    }
}

*:focus {
    outline: none;
}

.about-block-image svg {
    text-align: center;
}

.ant-drawer-body {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-top: 1.5rem;
}

.ant-drawer-content-wrapper {
    width: 300px !important;
}




.hoverBold:hover{
   
    color: #b336b3 
}


div.pricing{
    position: absolute;
    
    top:20%;bottom: 0;
    left:25%;
    
    height: 50%;
    align-items: center;
    margin-left:40px;
}

div.pricing.left:hover{
    animation: 0.3s peekLeftZoom 0.1s linear  forwards  ;
}
div.pricing.center:hover{
    animation: 0.3s peekZoom 0.1s linear  forwards  ;
}
div.pricing.right:hover{
    animation: 0.3s peekRightZoom 0.1s linear  forwards  ;
}

div.pricing img{
    position: relative;
    height: 100%;
    
}

div.pricing.center{
    /* position: relative !important; */
    
}
/* .revealed div.pricing.left{
    transition: transform 0.5s 0.6s;
    transform:translate(-125px,4%) rotate(-2.5deg);  
} */

.revealed div.pricing.left{
    transition: all 0.5s 0.6s;
    transform:rotate(-2.5deg);  
    left:5%;
    top:22%;
    width: 50px;
}
.revealed div.pricing.right{
    transition: all 0.5s 0.6s;
    transform:rotate(2.5deg);  
    left:45%;
    top:22%;
    width: 50px;
}

/* .revealed div.pricing.right{
    transition: transform 0.5s 0.6s;
    transform:translate(125px,4%) rotate(2.5deg);  
} */



.rotatingCog{
    position:absolute;
    animation:spin 14s linear infinite;
    align-content: center;
    justify-content: center;
}

.rotatingCogSmall{
    position:absolute;
    animation:spin-counter  8s linear infinite;

    align-content: center;
    justify-content: center;
}
