.productHeader{
    padding: 0.5rem 0.0rem;
    backdrop-filter: blur(20px);
    position: -webkit-sticky;
    position: sticky;
    top: 68px;
    z-index: 800;
    width:100%;
    background: rgba(255,255,255, 0.4);
    box-shadow: 0 0 25px rgba(120,0,0,0.15);
}

@media only screen and (max-width: 575px) {
    .productHeader{
        position: relative;
        top: 0;
        padding-bottom: 2.5rem;
    }
  }

img.nlpTask{
    opacity: 70%;
    height: 100px;
    width:  100px;
}