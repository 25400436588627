.video-responsive {
    overflow: hidden;
    
    position: relative;
    height: 480px;
    width: 853px;
    
    max-width: 100%;
   max-height: 56.25%
   
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    max-width: 100%;
    align-self: center;
    justify-self: center;
    position: absolute;
  }
  