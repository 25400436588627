.mainHeaderText{
    font-weight: 900;
    
    font-family: Futura, Verdana, Geneva, Tahoma, sans-serif;
        background: linear-gradient(200deg, #f38828 00%, #b336b3 80%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      
}

.secondSvgLine{
    margin-bottom: -350px; 
    

}
.secondSvgLine img{
    
    height: 300px;
    width: 70vw !important;
}
@media screen and (max-width: 992px)   {
    .secondSvgLine{
        display:none !important;
    }
}