
.blobImg{
    
    ;
    
}
.blobImgWrapperRight{
    margin-right: -90px;
    position: relative;
    right: -90px;
}

.blobImgWrapperLeft{
    margin-left: -90px;
    position: relative;
    left: -90px;
}

.picColContent{
    max-width: 600px;
    margin-top: 20%;
}

.colContent{
    max-width: 900px !important;
}